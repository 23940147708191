<template>
    <div>
      <div class="detail" style="margin-top: 95px;">
        <div class="container">
          <div class="right" style="overflow: scroll">
            <!-- <div v-for="(item, index) in imageList" :key="index">
              <el-image
                style="width: 100%"
                :src="item.imageUrl"
                :preview-src-list="[item.imageUrlList]"
              >
              </el-image>
            </div> -->
            <div class="previewWrap" style="display: flex">
              <!--放大镜效果-->
              <ImgList :ImgList="imageList.length != 0 ? imageList : []" style="margin-left: 2vw; margin-right: 2vw"
                @getIndex="changeImage" />
              <Zoom :ImgSrc="imageList.length != 0 ? imageList[imageIndex].imageUrl : ''" :ImageIndex="imageIndex"
                @getIndexImage="imageChange" />
              <!-- 小图列表 -->
            </div>
          </div>
          <div class="left" ref="leftContent">
            <div style="width: 75%">
              <!-- <InfoDetailDB v-if="organizationNames == '迪拜'" :type="goodType" style="width: 100%"></InfoDetailDB> -->
              <InfoDetail :type="goodType" style="width: 100%"></InfoDetail>
  
              <div>
                <div>
                  <div class="section4">
                    <span v-if="lange == '中文'">商品价格表</span>
                    <span v-else> Product Price List</span>
                  </div>
                  <div class="billList" style="padding: 20px">
                    <div class="item" style="width: 100%; margin-bottom: 25px">
                      <div class="content" style="width: 100%; font-size: 18px">
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">起订量</span>
                              <span v-else>Minimum Order Quantity</span>
                            </div>
                            <div class="msg" style="
                                width: 810px;
                                text-align: right;
                                padding: 15px 15px;
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ organizationNames == "迪拜" ? "DOZ/DHS" : "PCS/RMB" }}
                            </div>
                          </div>
                        </div>
                        <div class="items" style="width: 100%; display: flex"
                          v-for="(item, index) in currentItems.commodityPriceList" :key="index">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              {{ item.startNum }}
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 15px;
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ item.priceAfter }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="section4">
                    <span v-if="lange == '中文'">商品规格</span>
                    <span v-else>Product Specification</span>
                  </div>
                  <div class="billList" style="padding: 20px">
                    <div class="item" style="width: 100%; margin-bottom: 25px">
                      <div class="content" style="width: 100%; font-size: 20px">
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">产品名称</span>
                              <span v-else>Product Name</span>
                            </div>
  
                            <div v-if="currentItems.shoppingMallName" class="msg" style="
                                width: 810px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{
                                currentItems.brandName + "-" + currentItems.shoppingMallName
                              }}
                            </div>
                            <div v-else class="msg" style="
                                width: 810px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ currentItems.brandName }}
                            </div>
                          </div>
                        </div>
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">产品货号</span>
                              <span v-else>Product Code</span>
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ currentItems.cinvCode }}
                            </div>
                          </div>
                        </div>
  
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">产品材质</span>
                              <span v-else>Product Material</span>
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ currentItems.material }}
                            </div>
                          </div>
                        </div>
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">商品尺寸(厘米)</span>
                              <span v-else> Product Size(CM)</span>
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ currentItems.cinvStd }}
                            </div>
                          </div>
                        </div>
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">产品重量(公斤)</span>
                              <span v-else>Product Weight(KG)</span>
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ (currentItems.invweight * 1).toFixed(2) }}
                            </div>
                          </div>
                        </div>
  
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'"> 装箱数量(个)</span>
                              <span v-else>Packing specifications (PCS)</span>
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{ currentItems.packingQuantity }}
                            </div>
                          </div>
                        </div>
                        <div class="items" style="width: 100%; display: flex">
                          <div class="cell" style="width: 100%; display: flex">
                            <div class="title" style="
                                width: 712px;
                                padding: 15px 15px;
                                text-align: left;
  
                                border-bottom: 1px solid #8b8b8b;
                                font-weight: bold;
                              ">
                              <span v-if="lange == '中文'">库存(个)</span>
                              <span v-else>Stock(PCS)</span>
                            </div>
                            <div class="msg" style="
                                width: 800px;
                                text-align: right;
                                padding: 15px 0;
  
                                border-bottom: 1px solid #8b8b8b;
                              ">
                              {{
                                organizationNames == "迪拜"
                                  ? currentItems.availableQuantity1
                                  : currentItems.availableQuantity
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hotgoodsList.length != 0" class="new_goods_title_hot"
          style="display: flex; margin-top: 60px; justify-content: center">
          <span v-if="lange == '中文'"> 猜你喜欢</span>
          <span v-else>Lovely</span>
        </div>
        <div class="recommend clearfix" style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap">
          <div class="item fl" v-for="(item, index) in hotgoodsList" style="margin-bottom: 60px" :key="index">
            <dl>
              <dt @click="buyGoods(item)">
                <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%"> </imageSwiper>
              </dt>
  
              <div class="title" v-if="item.shoppingMallName">
                {{ item.brandName + "-" + item.cinvCode + "-" + item.shoppingMallName }}
              </div>
              <div class="title" v-else>
                {{ item.brandName + "-" + item.cinvCode }}
              </div>
              <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                <span class="price fl" v-if="organizationNames == '迪拜'">{{ parseFloat(item.price).toFixed(2)
                }}DHS/PCS</span>
                <span class="price fl" v-else>{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span>
              </div>
              <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                <div @click="deleteMultipe(item)">
                  <span class="btn" v-if="lange == '中文'">加入购物车</span>
                  <span class="btn" v-else>Add To Cart</span>
                </div>
                <div @click="buyGoods(item)" style="margin-left: 24px">
                  <span class="btns" style="margin-right: 15px" v-if="lange == '中文'">立即购买</span>
                  <span class="btns" style="margin-right: 15px" v-else>Order Now</span>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <addCartDialog ref="addCartDialog" v-if="addCartDialogShow" @back="addCartDialogShowClick" />
    </div>
  </template>
  
  <script>
  import {
    getDcBasearchiveCommodity,
   getStoreItemDetail,
    getDcBasearchiveCommodityFile,
    hostGood,
    dcBasearchiveCommodityRelate
  } from "@/api/good/index.js";
  import addCartDialog from "./addCartDialog/index.vue";
  import imageSwiper from "@/components/imageSwiper/index.vue";
  import shopOrder from "./shopOrder/index.vue";
  import Zoom from "./Detail/Zoom.vue";
  import ImgList from "./Detail/ImgList.vue";
  import InfoDetail from "./Detail/infoDetailXxd.vue";
  import InfoDetailDB from "./Detail/infoDetailXxd.vue";
  import InfoDetailOther from "./Detail/infoDetailOther.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "DetailPrd",
    data() {
      return {
        addCartDialogShow: true,
        isHome: false,
        skuId: undefined,
        dialogTableVisible: false,
        skuNum: 1,
        dialogImageUrl: "",
        currentItems: {},
        organizationNames: localStorage.getItem("organizationNames"),
        scrollHeight: 300,
        imageList: [],
        currentItem: {},
        hotgoodsList: [],
        imageIndex: 0,
        goodType: 0,
        discount: 1,
        addPrice: 0,
        brandName: this.$route.query.brandName,
      };
    },
    computed: {
      ...mapGetters({
        lange: "user/lange",
        organizationName: "user/organizationName",
        priceChangeList: "user/priceChangeList",
      }),
    },
    // computed: {
    //     ...mapState(['currentItem'])
    // },
    mounted() {
      // document.title = this.currentItem.content;
      // // 当前商品为空，跳装到首页
      // if (Object.keys(this.currentItem).length === 0) {
      //   this.$router.push({ name: "home" });
      // }
    },
    mounted() {
      if (this.priceChangeList.length != 0) {
        if (this.brandName == "CHRISBELLA") {
          let discountLength = this.priceChangeList.filter((item) => {
            return item.brandName == "CHRISBELLA";
          });
          if (discountLength.length != 0) {
            if (discountLength[0].discount) {
              this.discount = discountLength[0].discount / 100;
            } else {
              this.discount = 1;
            }
          }
          let addPriceLength = this.priceChangeList.filter((item) => {
            return item.brandName == "CHRISBELLA";
          });
          if (addPriceLength.length != 0) {
            if (addPriceLength[0].priceadjustment) {
              this.addPrice = addPriceLength[0].priceadjustment;
            } else {
              this.addPrice = 0;
            }
          }
        } else if (this.brandName == "BAGCO") {
          let discountLength = this.priceChangeList.filter((item) => {
            return item.brandName == "BAGCO";
          });
          if (discountLength.length != 0) {
            if (discountLength[0].discount) {
              this.discount = discountLength[0].discount / 100;
            } else {
              this.discount = 1;
            }
          }
          let addPriceLength = this.priceChangeList.filter((item) => {
            return item.brandName == "BAGCO";
          });
          if (addPriceLength.length != 0) {
            if (addPriceLength[0].priceadjustment) {
              this.addPrice = addPriceLength[0].priceadjustment;
            } else {
              this.addPrice = 0;
            }
          }
        } else {
          let discountLength = this.priceChangeList.filter((item) => {
            return item.brandName == "SUSEN零售";
          });
          if (discountLength.length != 0) {
            if (discountLength[0].discount) {
              this.discount = discountLength[0].discount / 100;
            } else {
              this.discount = 1;
            }
          }
          let addPriceLength = this.priceChangeList.filter((item) => {
            return item.brandName == "SUSEN零售";
          });
          if (addPriceLength.length != 0) {
            if (addPriceLength[0].priceadjustment) {
              this.addPrice = addPriceLength[0].priceadjustment;
            } else {
              this.addPrice = 0;
            }
          }
        }
      }
      getDcBasearchiveCommodityFile({
        cInvCode: this.$route.query.cinvCode,
        pageNum: 1,
        pageSize: 10,
        fileType: 3,
      }).then((res) => {
        if (res.data.length != 0) {
          this.imageList = res.data;
          this.imageList.forEach((item) => {
            if (item.fileurl) {
              item.imageUrl = this.$locationUrl + "file/" + item.fileurl;
              item.imageUrlList = [item.imageUrl];
              console.log(item.imageUrl);
            } else {
              item.imageUrl = "";
            }
          });
        } else {
          this.imageList = [];
        }
        console.log('213123123', this.imageList);
  
        getStoreItemDetail({
          cInvCode: this.$route.query.cinvCode,
          brandName: this.brandName,
          organizationId: this.organizationNames == "迪拜" ? 2 : 1,
          pageNum: 1,
          pageSize: 10,
        }).then((res) => {
          if (res.code == 200) {
            this.info = res.data.list[0];
            console.log('this.info',this.info);
            this.currentItems = res.data.list[0];
            if(this.organizationId==2){
      this.currentItems.availableQuantity=this.currentItems.availableQuantity1
    }
            this.currentItems.commodityPriceList.forEach((item) => {
  
              if (this.organizationNames == "迪拜") {
                item.priceAfter = (((item.priceAfter * 1 +this.addPrice) * this.discount)).toFixed(2);;
              } else {
                item.priceAfter = (item.priceAfter * 1 + this.addPrice * 1) * this.discount;
              }
            });
            console.log(this.info);
            this.goodType = 1;
  
          }
        });
      });
      console.log("----------------", this.addPrice, this.discount);
      dcBasearchiveCommodityRelate({
        pageSize: 100,
        pageNum: 1,
        cInvCode: this.$route.query.cinvCode,
        // brandName: this.brandName,
        // organizationId: this.organizationNames == "迪拜" ? 2 : 1,
      }).then((res) => {
        if (res.data.list.length != 0) {
          this.hotgoodsList = res.data.list;
          this.hotgoodsList.forEach((item) => {
            if (item.imageUrl) {
              item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
              item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
              console.log(item.imageUrl);
              item.imageUrlList = [item.imageUrl, item.imageUrl1];
            } else {
              item.imageUrl = "";
              item.imageUrl1 = "";
              item.imageUrlList = [item.imageUrl, item.imageUrl1];
            }
  
            item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
          });
        } else {
          this.hotgoodsList = [];
        }
  
        console.log(this.hotgoodsList);
      });
      console.log(this.$route.query.cinvCode);
      // window.addEventListener("scroll", this.handleScrollp);
      window.scrollTo(0, 0);
    },
    components: {
      Zoom,
      ImgList,
      InfoDetail,
      InfoDetailOther,
      shopOrder,
      imageSwiper,
      addCartDialog,
      InfoDetailDB,
    },
    methods: {
      addCartDialogShowClick() {
        this.addCartDialogShow = false;
        setTimeout(() => {
          this.addCartDialogShow = true;
        }, 500);
      },
      imageChange(index) {
        console.log(index);
        if (index >= this.imageList.length - 1) {
          this.imageIndex = 0;
        } else {
          this.imageIndex = index * 1 + 1 * 1;
        }
      },
      deleteMultipe(item) {
        this.$refs.addCartDialog.show(item.cinvCode);
      },
      handleScrollp() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 150) {
          this.$refs.leftContent.style.position = "sticky";
          this.$refs.leftContent.style.top = 0;
        }
        // if ( scrollTop < 993) {
  
        //   this.$refs.leftContent.style.top='150px'
        // }
        console.log(scrollTop);
      },
      buyGoods(item) {
        window.open(
          this.$locationViewUrl +
          "#/goods/Detail?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName,
          "_blank"
        );
      },
      dialogVisibleClick(item) {
        this.dialogVisible = true;
        this.dialogImageUrl = item;
      },
      changeImage(v) {
        console.log(v);
        let that = this;
        this.$nextTick(() => {
          that.imageIndex = v;
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .new_goods_title_hot {
    height: 40px;
  
    margin-top: 140px;
    margin-bottom: 60px;
  
    span {
      font-size: 40px;
      line-height: 28px;
  
      padding-left: 15px;
      font-weight: bold;
      font-size: 32px;
    }
  }
  
  .recommend {
    margin-top: 20px;
  
    .item {
      // height: 510px;
      margin-left: 20px;
      margin-bottom: 12px;
      width: 21%;
  
      .recommend_left {
        height: 280px;
        width: 360px;
  
        img {
          width: 400px;
          height: 320px;
        }
      }
  
      &:nth-child(1),
      &:nth-child(4n + 1) {
        margin-left: 0;
      }
  
      dl {
        width: 100%;
        // border: 1px solid #eee;
  
        img {
          width: 100%;
          // height: 400px;
          // &:hover {
          //   width: 440px;
          //   width: 440px;
          // }
        }
  
        dd {
          padding: 0 5px;
        }
  
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 15px;
          font-weight: 700;
          color: black;
          line-height: 28px;
        }
  
        .other {
          .price {
            font-size: 17px;
            color: black;
            font-weight: 600;
          }
  
          .wei {
            font-size: 14px;
            color: #666;
          }
        }
  
        .btn {
          line-height: 28px;
          text-align: center;
          color: black;
          border: 1px solid black;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 30px;
          /* margin: 6px auto 10px auto; */
          display: block;
          line-height: 20px;
          font-size: 12px;
          font-weight: 700;
          padding: 2px 10px;
  
          &:hover {
            color: #fff;
            background: black;
          }
        }
  
        .btns {
          line-height: 28px;
          text-align: center;
          color: black;
          border: 1px solid black;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 30px;
          /* margin: 6px auto 10px auto; */
          display: block;
          line-height: 20px;
          font-size: 12px;
          font-weight: 700;
          padding: 2px 10px;
  
          &:hover {
            color: #fff;
            background: black;
          }
        }
      }
    }
  
    .null {
      width: 188px;
      border: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .container {
    display: flex;
  }
  
  .left {
    /* 设置左侧模块高度为视口高度 */
    width: 35%;
    /* 设置左侧模块宽度 */
    display: flex;
    margin-left: 2vw;
  }
  
  .right {
    position: sticky;
    top: 0;
    flex: 1;
    height: 100vh;
    /* 设置右侧模块高度，使其能够滚动 */
  }
  
  .left::-webkit-scrollbar {
    display: none;
  }
  
  /* 隐藏 IE 和 Edge 浏览器滚动条 */
  .left {
    -ms-overflow-style: none;
  }
  
  .right::-webkit-scrollbar {
    display: none;
  }
  
  /* 隐藏 IE 和 Edge 浏览器滚动条 */
  .right {
    -ms-overflow-style: none;
  }
  
  .tb-item-info {
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    justify-content: space-between;
  }
  
  .mask {
    z-index: 10;
    top: -8px;
    right: 15px;
    width: 30px;
    height: 21px;
    text-align: center;
    background-color: #fff;
  }
  
  .section4 {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
    background: #fff;
  
    &>span {
      position: relative;
      display: inline-block;
      margin: 25px auto;
      font-weight: 700;
      font-size: 22px;
  
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        display: inline-block;
        background: -webkit-gradient(linear,
            left top,
            right top,
            from(rgba(255, 166, 0, 0.929412)),
            to(#ff6a00));
        background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
        width: 40%;
        border-radius: 50px;
        height: 4px;
      }
    }
  
    .article_list {
      text-align: left;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .article_item {
        border: 1px solid #eee;
        padding: 10px 30px;
        margin: 10px 0;
        width: 44%;
        cursor: pointer;
  
        .title {
          display: -webkit-box;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          margin-bottom: 10px;
        }
  
        .summary {
          color: #767676;
          font-size: 14px;
        }
  
        .title a {
          font-size: 18px;
          color: #333;
        }
  
        .title {
          p {
            color: #999;
          }
        }
      }
    }
  }
  
  .table-header {
    width: 92%;
    margin-left: 6%;
    margin-right: 2%;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN-Medium;
    color: #092553;
    font-weight: 900;
    font-size: 19px;
  }
  
  .table-header-title {
    border-radius: 2.5px;
    width: 5px;
    height: 16px;
    margin-right: 15px;
    background-color: #28bd9c;
  }
  
  .billList {
    padding: 20px;
  
    .item {
      margin-bottom: 25px;
  
      .name {
        padding: 15px 0;
        font-weight: bold;
        text-align: center;
        border: 1px solid #e8e8e8;
        font-size: 30px;
      }
  
      .content {
        font-size: 16px;
  
        .items {
          display: flex;
          // border-left: 1px solid #e8e8e8;
  
          .cell {
            width: 355px;
            display: flex;
  
            .title {
              width: 150px;
              padding: 15px 0;
              text-align: center;
              // border-right: 1px solid #e8e8e8;
              // border-bottom: 1px solid #e8e8e8;
              font-weight: bold;
            }
  
            .msg {
              width: 205px;
              text-align: center;
              padding: 15px 0;
              // border-right: 1px solid #e8e8e8;
              border-bottom: 1px solid #8b8b8b;
            }
          }
        }
      }
    }
  }
  </style>
  